import * as React from "react"

import LayoutComponent from "../components/layout/LayoutComponent"
import SeoComponent from "../components/seo/SeoComponent"

const NotFoundPage = () => (
  <LayoutComponent>
    <SeoComponent title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </LayoutComponent>
)

export default NotFoundPage
